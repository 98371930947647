export default function (Widget) {
    /**
     * @class HarmonyHeader
     * @augments Widget
     * @classdesc Used for setting/removing active state for header.
     */
    class HarmonyHeader extends Widget {
        prefs() {
            return {
                classActive: 'm-active',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.activeElements = 0;

            // Cache header element to manipulate with classes
            this.header = this.ref('self').get();

            this.events();
            this.setActiveNav();
        }

        events() {
            this.eventBus().on('header.set.active.state', 'activate');
            this.eventBus().on('header.remove.active.state', 'deactivate');
            this.eventBus().on('product.added.to.wishlist', 'fillWishlistIcon');
            this.eventBus().on('wishlist.empty', 'cleanWishlistIcon');
        }

        activate() {
            this.activeElements++;
            this.header.classList.add(this.prefs().classActive);
        }

        deactivate() {
            this.activeElements--;

            if (this.activeElements < 1) {
                this.header.classList.remove(this.prefs().classActive);
            }
        }

        fillWishlistIcon() {
            this.has('wishlistIcon', /** @param {RefElement} item */ (item) => {
                const activeClass = item.data('activeClass');
                item.addClass(activeClass);
            });
        }

        cleanWishlistIcon() {
            this.has('wishlistIcon', /** @param {RefElement} item */ (item) => {
                const activeClass = item.data('activeClass');
                item.removeClass(activeClass);
            });
        }
    }

    return HarmonyHeader;
}
